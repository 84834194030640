.sidebar-nav ul .sidebar-item.selected > .sidebar-link
{
    background:gray;
}

.left-sidebar {
    padding-top: 60px;
}

.navbar-collapse[data-navbarbg="skin6"], #main-wrapper[data-layout="vertical"] .topbar[data-navbarbg="skin6"], #main-wrapper[data-layout="horizontal"] .topbar .navbar-collapse[data-navbarbg="skin6"], #main-wrapper[data-layout="horizontal"] .topbar[data-navbarbg="skin6"] {
    background: #eab131 !important;
}

#main-wrapper[data-layout="vertical"] .topbar .top-navbar .navbar-header[data-logobg="skin6"], #main-wrapper[data-layout="horizontal"] .topbar .top-navbar .navbar-header[data-logobg="skin6"] {
    background: #20222a;
    border-bottom: 1px solid darkgray;
}

.auth-wrapper .auth-box {
    background: #66ADFF;
}
.navbar-collapse[data-navbarbg="skin6"], #main-wrapper[data-layout="vertical"] .topbar[data-navbarbg="skin6"], #main-wrapper[data-layout="horizontal"] .topbar .navbar-collapse[data-navbarbg="skin6"], #main-wrapper[data-layout="horizontal"] .topbar[data-navbarbg="skin6"] {
    background: #E84545 !important;
}
.ReactTable .rt-tbody .rt-td {
    /* border-bottom: 1px solid #646464; */
    border-right: 1px solid #646464 !important;
    border-top: 1px solid #646464;
    padding: 4px;
}

.ReactTable .rt-tbody .rt-td:last-child {
    border-right: none !important;
}

.ReactTable .rt-thead.-filters .rt-th {
    /* border-bottom: 1px solid #646464; */
    border-right: 1px solid #646464 !important;
}

.ReactTable .rt-thead.-filters .rt-th:last-child {
    /* border-bottom: 1px solid #646464; */
    border-right: none !important;
}

.ReactTable {
    border: 1px solid #646464 !important;
}

.ReactTable .rt-thead .rt-resizable-header {
    background-color: #d44548;
    color: white;
    padding: 10px;
}
.btn-secondary {
    color: #fff;
    background-color: #66ADFF;
    border-color: #8898aa;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}
.bootstrap-switch .bootstrap-switch-handle-off, .bootstrap-switch .bootstrap-switch-handle-on, .bootstrap-switch .bootstrap-switch-label {

    line-height: unset !important;
    
  }

  .card-title {
    margin-bottom: 0.75rem;
    text-transform: uppercase;
    font-size: 1.2rem;
  }
  /* .form-control {
   
    background-color: #3c424e;
}
.modal-body {
   
    background-color: #20222a;
}
.justify-content-center {
    background-color: #20222a;
} */

.sidebar-nav ul .sidebar-item .sidebar-link {
    opacity: 0.9 !important;
}
.auth-wrapper .auth-box {
    background: #c3afaf;
}
.page-wrapper > .page-content {
    padding: 15px;
}

.topbar .top-navbar .navbar-header .navbar-brand {
    padding: 0 10px 0 0px;
}
.background
{
    background-color: #181A20
    center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    }

    .wrapper{
        border: 1px solid grey;
      }
      .editor{
        background-color: lavender;
      }